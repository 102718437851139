import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {})),
            filterConjunction: input.filterConjunction || 'and'
        }
    );

    return apiClient.get('/api/v1/shipments', {params});
};

export const getAllOpen = (warehouseId: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/open-shipments?warehouseId=' + warehouseId);
};

export const getAllHeadless = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {})),
            filterConjunction: input.filterConjunction || 'and'
        }
    );

    return apiClient.get('/api/v1/headless-shipments', {params});
};

export const getSingle = (
    shipmentNumber: string | string[]
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/shipments/' + shipmentNumber);
};

export const update = async (
    shipmentNumber: string,
    shipmentInfo: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/shipments/' + shipmentNumber, shipmentInfo);
};

export const moveToNextStatus = async (
    shipmentNumber: string
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/shipments/' + shipmentNumber + '/move-to-next-status'
    );
};

export const announceAtShippingCarrier = async (
    shipmentNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/shipments/' + shipmentNumber + '/announce-at-shipping-carrier',
        payload
    );
};

export const sendUpsLabelToRemotePrinter = async (
    shipmentNumber: string,
    trackingNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/shipments/' + shipmentNumber + '/print-label',
        Object.assign(payload, {trackingNumber})
    );
};

export const invokeUpsAcceptancePreCheck = async (
    shipmentNumber: string,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post(
        '/api/v1/shipments/' + shipmentNumber + '/ups-acceptance-pre-check',
        payload
    );
};

export const createHeadlessShipment = async (
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/headless-shipments', payload);
};

export const getAvailableCarriersForHeadless = (): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/headless-shipments/available-carriers');
};

export const getSingleHeadlessShipmentLabel = (
    shipmentId: string
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/headless-shipments/' + shipmentId + '/label',
        {
            responseType: 'blob'
        }
    );
};

export const getCombinedHeadlessShipmentLabel = (
    shipmentId: string
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/headless-shipments/' + shipmentId + '/combined-label',
        {
            responseType: 'blob'
        }
    );
};

export const cancelHeadlessShipment = (id: string): Promise<AxiosResponse> => {
    return apiClient.delete('/api/v1/headless-shipments/' + id);
};
