import {computed, ref, watch} from 'vue';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Fieldset from 'primevue/fieldset';
import {useVuelidate} from '@vuelidate/core';
import {i18n} from '@/utils/i18n';
import Editor from '@tinymce/tinymce-vue';
import {maxLength} from '@vuelidate/validators';
import Checkbox from 'primevue/checkbox';
import store from '@/store';
import Panel from 'primevue/panel';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        pairValues: {
            type: Object,
            default: null
        }
    },
    components: {
        InputText,
        'p-button': Button,
        'p-fieldset': Fieldset,
        'p-checkbox': Checkbox,
        tiny: Editor,
        Panel
    },
    setup(props: any, context: any) {
        const pairValues = ref(null);
        const submitted = ref(false);

        const existingShopwareData = ref(null);

        const fieldDefinitions: any = {
            webshopText: null,
            webshopText2: null,
            shortDescription: null,
            longDescription: null,
            availableInShop: false,
            activeInShop: false,
            availableInShop2: false,
            activeInShop2: false,
            copyShopAccessories: false,
            copyShopImages: false,
            copyShopVideos: false,
            copyShopLinks: false
        };

        const state = ref(Object.assign({}, fieldDefinitions));

        const rules = computed(() => {
            const localRules: any = {};

            Object.keys(fieldDefinitions).forEach((key: string) => {
                if ('shortDescription' === key) {
                    localRules[key as keyof typeof localRules] = {
                        maxLength: maxLength(100)
                    };
                } else {
                    localRules[key as keyof typeof localRules] = {};
                }
            });

            return localRules;
        });

        const v$ = useVuelidate(rules, state);

        const prePopulateForm = () => {
            state.value['webshopText'] = pairValues.value?.webshopText || null;

            state.value['shortDescription'] =
                pairValues.value?.shortDescription ||
                pairValues.value?.shortDescription1 ||
                state.value['shortDescription'];

            state.value['longDescription'] =
                pairValues.value?.description || null;

            existingShopwareData.value = pairValues.value?.shopwareData || null;
            if (existingShopwareData.value) {
                state.value['copyShopAccessories'] =
                    existingShopwareData.value.accessories.length > 0;
                state.value['copyShopImages'] =
                    existingShopwareData.value.images.length > 0;
                state.value['copyShopVideos'] =
                    existingShopwareData.value.videos.length > 0;
                state.value['copyShopLinks'] =
                    existingShopwareData.value.links.length > 0;
            }

            for (const key in state.value) {
                if (Object.keys(pairValues.value).includes(key)) {
                    state.value[key] = pairValues.value[key];
                }
            }
        };
        watch(props, (args) => {
            if (args.pairValues && pairValues.value === null) {
                pairValues.value = args.pairValues;
                prePopulateForm();
            }
        });

        watch(v$, (args) => {
            if (args.$anyDirty) {
                context.emit('add-wizard-form-dirty', {});
            }
        });

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            context.emit('next-button-clicked', {
                stepIndex: 2,
                formValues: state.value
            });
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 2);
        };

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const hasSecondShop = computed(async () => {
            return (
                ['ttl', 'ttd'].indexOf(
                    resolvedPlatform.value?.threeLetterId
                ) !== -1
            );
        });

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            onBackButtonClicked,
            locale: i18n.global.locale,
            onActiveInShopChange: (fieldName: string, newValue: boolean) => {
                if (newValue) {
                    state.value[fieldName] = true;
                }
            },
            existingShopwareData,
            hasSecondShop,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL
        };
    }
};
