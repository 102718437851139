import {onMounted, ref, watch} from 'vue';
import Panel from 'primevue/panel';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import useVuelidate from '@vuelidate/core';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import Editor from '@tinymce/tinymce-vue';
import {updateDetails} from '@/services/tickets';
import {i18n} from '@/utils/i18n';
import {getAll} from '@/services/metadata';
import {getAll as getAllProducts} from '@/services/products';
import {getAll as getAllSalesInvoices} from '@/services/sales-invoices';
import {getAll as getAllSalesOrders} from '@/services/sales-orders';
import Dropdown from 'primevue/dropdown';
import {helpers, required} from '@vuelidate/validators';
import AutoComplete from 'primevue/autocomplete';
import {FilterMatchMode} from 'primevue/api';

export default {
    emits: ['reload-ticket-details'],
    components: {
        Panel,
        'p-textarea': Textarea,
        'p-inputtext': InputText,
        'p-button': Button,
        'p-dropdown': Dropdown,
        AutoComplete,
        tiny: Editor
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const submitted = ref(false);
        const ticketDetails = ref(null);
        const savingInProgress = ref(false);
        const toast = useToast();
        const userOptions = ref([]);
        const ticketStatusOptions = ref([]);
        const filteredProducts = ref([]);
        const filteredSalesInvoices = ref([]);
        const filteredSalesOrders = ref([]);

        onMounted(() => {
            getAll(['user', 'ticketStatus'])
                .then((data) => {
                    if (data.data?.user) {
                        userOptions.value = (data.data.user || []).filter(
                            (usr: any) =>
                                usr.licenses.includes('ADDON_HELPDESK')
                        );
                    }

                    if (data.data?.ticketStatus) {
                        ticketStatusOptions.value =
                            data.data.ticketStatus || [];
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        watch(props, (args) => {
            if (args.ticketDetails) {
                ticketDetails.value = args.ticketDetails;
                state.value = Object.assign(state.value, {
                    subject: ticketDetails.value?.subject,
                    description: ticketDetails.value?.description,
                    assignedUserId: ticketDetails.value?.assignedUserId,
                    ticketStatusId: ticketDetails.value?.ticketStatusId,
                    ticketArticleId: ticketDetails.value?.ticketArticle
                        ? {
                              label:
                                  '(' +
                                  ticketDetails.value?.ticketArticle
                                      ?.articleNumber +
                                  ') ' +
                                  ticketDetails.value?.ticketArticle?.name,
                              value: ticketDetails.value?.ticketArticle?.id
                          }
                        : null,

                    ticketSalesInvoiceId: ticketDetails.value
                        ?.ticketSalesInvoice
                        ? {
                              label:
                                  ticketDetails.value?.ticketSalesInvoice
                                      ?.invoiceNumber +
                                  ' (' +
                                  ticketDetails.value?.ticketSalesInvoice
                                      ?.customer +
                                  ')',
                              value: ticketDetails.value?.ticketSalesInvoice?.id
                          }
                        : null,
                    ticketSalesOrderId: ticketDetails.value?.ticketSalesOrder
                        ? {
                              label:
                                  ticketDetails.value?.ticketSalesOrder
                                      ?.orderNumber +
                                  ' (' +
                                  ticketDetails.value?.ticketSalesOrder
                                      ?.customer +
                                  ')',
                              value: ticketDetails.value?.ticketSalesOrder?.id
                          }
                        : null,
                    ticket_external_rma:
                        ticketDetails.value?.customAttributes
                            ?.ticket_external_rma,
                    ticket_internal_comment:
                        ticketDetails.value?.customAttributes
                            ?.ticket_internal_comment,
                    ticket_serialnumber:
                        ticketDetails.value?.customAttributes
                            ?.ticket_serialnumber,
                    ticket_inbound_tracking:
                        ticketDetails.value?.customAttributes
                            ?.ticket_inbound_tracking
                });
            }
        });

        const searchProducts = (event: any) => {
            getAllProducts(
                {
                    first: 0,
                    rows: 20,
                    columns: ['id', 'articleNumber', 'name'],
                    filters: {
                        name: {
                            value: event?.query || '',
                            matchMode: FilterMatchMode.CONTAINS
                        },
                        articleNumber: {
                            value: event?.query || '',
                            matchMode: FilterMatchMode.CONTAINS
                        }
                    },
                    filterConjunction: 'or'
                },
                true
            )
                .then((data) => {
                    if (data.data?.items) {
                        filteredProducts.value = data.data.items.map(
                            (item: {
                                id: string;
                                articleNumber: string;
                                name: string;
                            }) => {
                                return {
                                    label:
                                        '(' +
                                        item.articleNumber +
                                        ') ' +
                                        item.name,
                                    value: item.id
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const searchSalesInvoices = (event: any) => {
            getAllSalesInvoices({
                first: 0,
                rows: 5,
                columns: ['id', 'invoiceNumber', 'customerId'],
                filters: {
                    invoiceNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filterConjunction: 'or'
            })
                .then((data) => {
                    if (data.data?.items) {
                        filteredSalesInvoices.value = data.data.items.map(
                            (item: {
                                id: string;
                                invoiceNumber: string;
                                customer: any;
                            }) => {
                                return {
                                    label:
                                        item?.invoiceNumber +
                                        ' (' +
                                        (item?.customer?.company ||
                                            item?.customer?.personCompany) +
                                        ')',
                                    value: item?.id
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const searchSalesOrders = (event: any) => {
            getAllSalesOrders({
                first: 0,
                rows: 5,
                columns: ['id', 'orderNumber', 'customerId'],
                filters: {
                    orderNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filterConjunction: 'or'
            })
                .then((data) => {
                    if (data.data?.items) {
                        filteredSalesOrders.value = data.data.items.map(
                            (item: {
                                id: string;
                                orderNumber: string;
                                customer: any;
                            }) => {
                                return {
                                    label:
                                        item?.orderNumber +
                                        ' (' +
                                        (
                                            item?.customer?.company ||
                                            (item?.customer?.firstName || '') +
                                                ' ' +
                                                (item?.customer?.lastName || '')
                                        ).trim() +
                                        ')',
                                    value: item?.id
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const state = ref({
            subject: null,
            description: null,
            assignedUserId: null,
            ticketStatusId: null,
            ticketArticleId: null,
            ticketSalesInvoiceId: null,
            ticketSalesOrderId: null,
            ticket_external_rma: null,
            ticket_internal_comment: null,
            ticket_serialnumber: null,
            ticket_inbound_tracking: null
        });

        const rules = {
            subject: {},
            description: {},
            assignedUserId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            ticketStatusId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            ticketArticleId: {},
            ticketSalesInvoiceId: {},
            ticketSalesOrderId: {},
            ticket_external_rma: {},
            ticket_internal_comment: {},
            ticket_serialnumber: {},
            ticket_inbound_tracking: {}
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;

            if (!isFormValid) {
                return;
            }

            savingInProgress.value = true;

            updateDetails(ticketDetails.value.ticketNumber, {
                ...state.value,
                ticketArticleId: state.value?.ticketArticleId?.value || '',
                ticketSalesInvoiceId:
                    state.value?.ticketSalesInvoiceId?.value || '',
                ticketSalesOrderId: state.value?.ticketSalesOrderId?.value || ''
            })
                .then(() => {
                    toast.success(
                        i18n.global.t('messages.changesSavedSuccessfully'),
                        {
                            timeout: 500
                        }
                    );
                    context.emit('reload-ticket-details');
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    savingInProgress.value = false;
                });
        };

        return {
            ticketDetails,
            state,
            v$,
            submitted,
            savingInProgress,
            handleSubmit,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL,
            userOptions,
            ticketStatusOptions,
            searchProducts,
            filteredProducts,
            searchSalesInvoices,
            filteredSalesInvoices,
            searchSalesOrders,
            filteredSalesOrders
        };
    }
};
