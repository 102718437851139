import {ref, watch} from 'vue';
import Textarea from 'primevue/textarea';

export default {
    components: {
        'p-textarea': Textarea
    },
    props: {
        ticketDetails: Object
    },
    setup(props: any) {
        const ticketDetails = ref(null);

        watch(props, (args) => {
            if (args.ticketDetails) {
                ticketDetails.value = args.ticketDetails;
            }
        });

        return {
            ticketDetails
        };
    }
};
