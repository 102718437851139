import TabPanel from 'primevue/tabpanel';
import Panel from 'primevue/panel';
import Fieldset from 'primevue/fieldset';
import Button from 'primevue/button';
import AddTicketEmailDialog from '@/components/dialog/tickets/add-ticket-email.vue';
import {computed, ref, watch} from 'vue';
import store from '@/store';

export default {
    components: {
        TabPanel,
        Panel,
        'p-button': Button,
        'p-fieldset': Fieldset,
        AddTicketEmailDialog
    },
    emits: ['reload-ticket-details'],
    props: {
        ticketDetails: Object
    },
    setup(props: any, context: any) {
        const showAddEmailDialog = ref(false);
        const ticketDetails = ref(null);

        watch(props, (args) => {
            if (args.ticketDetails) {
                ticketDetails.value = args.ticketDetails;
            }
        });

        const onAddRMARequestButtonClick = () => {
            showAddEmailDialog.value = true;
        };

        const closeAddTicketEmailDialog = (event: any) => {
            showAddEmailDialog.value = false;
            if (event) {
                context.emit('reload-ticket-details');
            }
        };

        const loggedInUser = computed(() => {
            return store.getters['auth/user']?.username;
        });

        const computedEmailSubject = computed(() => {
            const subject = (ticketDetails.value?.subject || '')
                .split('|')
                .filter((item: string) => {
                    const trimmed = item.trim();
                    return (
                        trimmed !== '' &&
                        !trimmed.includes(
                            ticketDetails.value?.customer?.company
                        ) &&
                        (!ticketDetails.value?.customer?.company ||
                            !ticketDetails.value.customer.company.includes(
                                trimmed
                            )) &&
                        !trimmed.includes(
                            ticketDetails.value?.customer?.firstName
                        ) &&
                        !trimmed.includes(
                            ticketDetails.value?.customer?.lastName
                        )
                    );
                });

            return (
                '[' +
                ticketDetails.value?.ticketNumber +
                '] - ' +
                subject.join('|')
            );
        });

        const computedEmailBody = computed(() => {
            if (ticketDetails.value?.description) {
                return ticketDetails.value?.description.replace(
                    /(KUNDENDATEN(.+?))PRODUKTDATEN/gims,
                    'PRODUKTDATEN'
                );
            }
            return '';
        });

        return {
            ticketDetails,
            showAddEmailDialog,
            closeAddTicketEmailDialog,
            onAddRMARequestButtonClick,
            loggedInUser,
            computedEmailSubject,
            computedEmailBody
        };
    }
};
