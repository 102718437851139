import {computed, onMounted, ref} from 'vue';
import {getAll as getAllBadges} from '@/services/product-badges';
import {getAll as getAllMetadata} from '@/services/metadata';
import {getAll} from '@/services/products';
import LoadingPlugin from 'vue-loading-overlay';
import DataTable from 'primevue/datatable';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Column from 'primevue/column';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import TriStateCheckbox from 'primevue/tristatecheckbox';
import MultiSelect from 'primevue/multiselect';
import Tag from 'primevue/tag';
import Badge from 'primevue/badge';
import TieredMenu from 'primevue/tieredmenu';
import FilterMenu from '@/components/filter-menu/filter-menu.vue';
import BulkAddAccessories from '@/components/dialog/products/bulk-add-accessories.vue';
import BulkAddRemoveBadges from '@/components/dialog/products/bulk-add-remove-badges.vue';
import BulkAddRemoveCategories from '@/components/dialog/products/bulk-add-remove-categories.vue';
import BulkAddRemoveDiscounts from '@/components/dialog/products/bulk-add-remove-discounts.vue';
import BulkAddWeclappDocuments from '@/components/dialog/products/bulk-add-weclapp-documents.vue';
import BulkAddShopwareDocuments from '@/components/dialog/products/bulk-add-shopware-documents.vue';
import BulkSetShopOnlineStatus from '@/components/dialog/products/bulk-set-shop-online-status.vue';
import BulkEditPromotions from '@/components/dialog/products/bulk-edit-promotions.vue';
import BulkEditMetatags from '@/components/dialog/products/bulk-edit-metatags.vue';
import BulkEditCompliance from '@/components/dialog/products/bulk-edit-compliance.vue';
import BulkEditTags from '@/components/dialog/products/bulk-edit-tags.vue';
import BulkEditCustomFields from '@/components/dialog/products/bulk-edit-custom-fields.vue';
import SalesChannelSwitcher from '@/components/sales-channel-switcher/sales-channel-switcher.vue';

import {i18n} from '@/utils/i18n';
import {FilterMatchMode} from 'primevue/api';
import router from '@/router/index';
import {useToast} from 'vue-toastification';
import store from '@/store';
import {
    stripTagsAndTruncate,
    vkPriceCalculation,
    ekPriceCalculation,
    uvpPriceCalculation,
    isValueEqualCaseInsensitive,
    getShopwareUrl,
    isShopware5
} from '@/utils/helpers';
import {getAllForMultipleProducts} from '@/services/product-changelog';
import {ILayoutConfig} from '@/interfaces/layout/config';

export default {
    components: {
        DataTable,
        InputText,
        InputSwitch,
        Dropdown,
        'p-button': Button,
        'p-multiselect': MultiSelect,
        FilterMenu,
        Column,
        Calendar,
        Badge,
        BulkAddAccessories,
        loading: LoadingPlugin,
        BulkAddRemoveBadges,
        BulkAddRemoveCategories,
        BulkAddRemoveDiscounts,
        PrimeDialog: Dialog,
        PrimePanel: Panel,
        TriStateCheckbox,
        BulkAddWeclappDocuments,
        BulkAddShopwareDocuments,
        BulkSetShopOnlineStatus,
        BulkEditPromotions,
        BulkEditMetatags,
        BulkEditCompliance,
        BulkEditTags,
        BulkEditCustomFields,
        TieredMenu,
        Tag,
        SalesChannelSwitcher
    },
    setup() {
        onMounted(async () => {
            const result = await getAllBadges();
            badges.value = result.data;

            getAllMetadata(['productTags']).then((data) => {
                availableProductTags.value = data.data?.productTags || [];
            });

            if (localStorage.getItem('products-list-state-session')) {
                return;
            }

            tableState.value = null;
            filters.value = Object.assign({}, filtersDefinition);

            lazyParams.value = {
                first: 0,
                rows: dt.value.rows,
                sortField: 'articleNumber',
                sortOrder: 1,
                filters: filters.value
            };

            loadLazyData();
        });

        const dialogDefinitions: Array<{
            name: string;
            visible: boolean;
            usingFilter: boolean;
            mainProductSelected?: boolean;
            isRemove?: boolean;
        }> = [
            {
                name: 'Comments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'Accessories',
                visible: false,
                usingFilter: false,
                mainProductSelected: false
            },
            {
                name: 'Badges',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Categories',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Discounts',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'WeclappDocuments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'ShopwareDocuments',
                visible: false,
                usingFilter: false
            },
            {
                name: 'OnlineStatus',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Promotions',
                visible: false,
                usingFilter: false
            },
            {
                name: 'Metatags',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Tags',
                visible: false,
                usingFilter: false,
                isRemove: false
            },
            {
                name: 'Compliance',
                visible: false,
                usingFilter: false
            },
            {
                name: 'CustomFields',
                visible: false,
                usingFilter: false
            }
        ];

        const dialogs = ref(dialogDefinitions);

        const closeDialog = () => {
            dialogs.value.forEach((item) => {
                item.visible = false;
            });
        };

        const openDialog = (dialogParameters: {
            dialogName: string;
            usingFilter?: boolean;
            mainProductSelected?: boolean;
            isRemove?: boolean;
        }) => {
            closeDialog();
            const chosenDialog = dialogs.value.find(
                (item) => item.name === dialogParameters.dialogName
            );
            chosenDialog.visible = true;
            chosenDialog.usingFilter = dialogParameters.usingFilter || false;
            if (typeof chosenDialog['mainProductSelected'] !== 'undefined') {
                chosenDialog.mainProductSelected =
                    dialogParameters.mainProductSelected || false;
            }

            if (typeof chosenDialog['isRemove'] !== 'undefined') {
                chosenDialog.isRemove = dialogParameters.isRemove || false;
            }
        };

        const tableState = ref(null);
        const totalRecords = ref(0);
        const loading = ref(false);
        const products = ref();
        const selectedProducts = ref([]);
        const mergedSelection = ref([]);
        const toast = useToast();
        const badges = ref([]);
        const changelog = ref([]);

        const availableProductTags = ref([]);

        const dt = ref();
        const lazyParams: any = ref({});
        const expandedRows = ref([]);

        const columnSelectorComponent = ref(null);

        const bulkMenu = ref();

        const comments: any = ref({});

        const onPage = (event: any) => {
            if (!lazyParams.value) {
                lazyParams.value = event;
            } else {
                lazyParams.value.first = event.first || 0;
                lazyParams.value.rows = event.rows || dt.value.rows;
            }
            loadLazyData();
        };

        const onFilter = () => {
            lazyParams.value.filters = Object.assign(filters.value, {});
            lazyParams.value.first = 0;
            loadLazyData();
        };

        const onSort = (event: any) => {
            lazyParams.value = event;

            lazyParams.value.filters = Object.assign(filters.value, {});

            loadLazyData();
        };

        const onStateSave = (event: any) => {
            localStorage.setItem(
                'products-list-state-session',
                JSON.stringify(
                    Object.assign(event, {
                        visibleColumns: visibleColumns.value,
                        selectedColumns: selectedColumns.value
                    })
                )
            );
        };

        const onStateRestore = (event: any) => {
            if (localStorage.getItem('products-list-state-session')) {
                const stateDump = JSON.parse(
                    localStorage.getItem('products-list-state-session')
                );

                if (stateDump.visibleColumns) {
                    visibleColumns.value = stateDump.visibleColumns;
                }

                if (stateDump.selectedColumns) {
                    selectedColumns.value = stateDump.selectedColumns;
                }
            }

            tableState.value = Object.assign({}, event);

            const stateFilters = Object.keys(
                tableState.value?.filters || {}
            ).filter((e) => e !== 'view');

            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            if (
                !stateFilters.every((val) =>
                    filtersDefinitionKeys.includes(val)
                )
            ) {
                localStorage.removeItem('products-list-state-session');
                tableState.value = null;
                filters.value = Object.assign({}, filtersDefinition);
            } else {
                delete event.filters?.view;
                filters.value = Object.assign(
                    {},
                    filtersDefinition,
                    event.filters || {}
                );
            }

            lazyParams.value = {
                first: 0,
                rows: event?.rows || 10,
                sortField: event?.sortField || 'articleNumber',
                sortOrder: event?.sortOrder || 1,
                filters: filters.value
            };

            loadLazyData();
        };

        const clearFilters = () => {
            filters.value = Object.assign({}, filtersDefinition);
            onFilter();
        };

        const applyFilters = (savedFilters: any) => {
            const filtersDefinitionKeys = Object.keys(filtersDefinition)
                .slice()
                .sort();

            const filtered = Object.keys(savedFilters)
                .filter((key) => filtersDefinitionKeys.includes(key))
                .reduce((obj: any, key) => {
                    obj[key as keyof typeof obj] = savedFilters[key];
                    return obj;
                }, {});

            filters.value = Object.assign({}, filtersDefinition, {
                ...filtered
            });
            onFilter();
        };

        const matchModesNumeric = [
            {label: 'Equals', value: FilterMatchMode.EQUALS},
            {label: 'Not Equals', value: FilterMatchMode.NOT_EQUALS},
            {label: 'Less Than', value: FilterMatchMode.LESS_THAN},
            {
                label: 'Less or Equal',
                value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
            },
            {label: 'Greater Than', value: FilterMatchMode.GREATER_THAN},
            {
                label: 'Greater or Equal',
                value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            }
        ];

        const loadLazyData = () => {
            loading.value = true;
            lazyParams.value.columns = visibleColumns.value.concat([
                'comments'
            ]);
            getAll(lazyParams.value)
                .then((data) => {
                    totalRecords.value = data.data.total;
                    products.value = data.data.items;
                    selectedProducts.value = mergedSelection.value.filter(
                        (item: any) => {
                            return products.value.some(
                                (product: any) =>
                                    product.articleNumber === item.articleNumber
                            );
                        }
                    );
                    getAllForMultipleProducts(
                        products.value.map((item: any) => item.articleNumber)
                    )
                        .then((clog) => {
                            changelog.value = clog.data;
                        })
                        .catch((error) => {
                            toast.error(
                                error.response?.data?.error || error.message
                            );
                        })
                        .finally(() => {
                            loading.value = false;
                        });
                })
                .catch((error) => {
                    toast.error(error.response?.data?.error || error.message);
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const getBadgeName = (badgeId: string) => {
            if (!badges.value) {
                return '';
            }

            const found = badges.value.find(
                (item) => item.id.toString() === badgeId
            );

            return found ? found.name : '';
        };

        const filtersDefinition: any = {
            articleNumber: {value: null, matchMode: FilterMatchMode.CONTAINS},
            /*teltecProductNumber: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            videodataProductNumber: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },*/
            name: {value: null, matchMode: FilterMatchMode.CONTAINS},
            productCategory: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            productBadges: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            articleCategoryName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            description: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            shortDescription1: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            ean: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            manufacturerPartNumber: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            sellOut: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            priceOnRequest: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            accessories: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            manufacturerName: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            metaTags: {
                value: null,
                matchMode: FilterMatchMode.CONTAINS
            },
            bulkGoods: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            blackWeekPromoFlag: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            orderPositionCheckStock: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            tempSelect: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            activeInShop: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            availableInShop: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            availableStock: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            createdDate: {value: null, matchMode: FilterMatchMode.DATE_IS},
            tags: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            }
        };

        const filters = ref(filtersDefinition);

        const resolvedPlatform = computed(() => {
            return store.getters['auth/platforms'].find(
                (item: any) => item.value === store.getters['auth/platform']
            );
        });

        const secondPlatform = computed(() => {
            switch (resolvedPlatform.value?.threeLetterId) {
                case 'ttd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdd'
                    );
                case 'ttl':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'vdl'
                    );
                case 'vdd':
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttd'
                    );
                default:
                    return store.getters['auth/platforms'].find(
                        (item: any) => item.value === 'ttl'
                    );
            }
        });

        const renderShopwareCategory = (
            element: {
                name: string;
                breadcrumb: Array<string>;
            },
            renderName: boolean = false
        ) => {
            return !element.breadcrumb ||
                isShopware5(resolvedPlatform.value?.threeLetterId) ||
                renderName
                ? element.name
                : element.breadcrumb.join(' / ');
        };

        const addBulkItems = computed(() => {
            return [
                {
                    label: i18n.global.t('labels.articleAccessories'),
                    icon: 'pi pi-sitemap',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.useSelectedAsMainProduct'
                            ),
                            icon: 'pi pi-sitemap',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Accessories',
                                    mainProductSelected: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.useSelectedAsAccessory'
                            ),
                            icon: 'pi pi-link',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Accessories'
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.productHome.activeInShop'),
                    icon: 'pi pi-shopping-cart',
                    items: [
                        {
                            label:
                                i18n.global.t('labels.putSelectedOffline', {
                                    number: mergedSelection.value?.length || ''
                                }) + '...',
                            icon: 'pi pi-circle-fill text-red-600',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                openDialog({
                                    dialogName: 'OnlineStatus',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label:
                                i18n.global.t('labels.putSelectedOnline', {
                                    number: mergedSelection.value?.length || ''
                                }) + '...',
                            icon: 'pi pi-circle-fill text-green-600',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                openDialog({
                                    dialogName: 'OnlineStatus'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.putAllOffline', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-circle-fill text-red-600',
                            command: () => {
                                openDialog({
                                    dialogName: 'OnlineStatus',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.putAllOnline', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-circle-fill text-green-600',
                            command: () => {
                                openDialog({
                                    dialogName: 'OnlineStatus',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.badges'),
                    icon: 'pi pi-tag',
                    items: [
                        {
                            label: i18n.global.t('labels.addBadgesToSelected', {
                                number: mergedSelection.value?.length || ''
                            }),
                            icon: 'pi pi-tag',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }

                                openDialog({dialogName: 'Badges'});
                            }
                        },
                        {
                            label: i18n.global.t('labels.addBadgesToAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-tag',
                            command: () => {
                                openDialog({
                                    dialogName: 'Badges',
                                    usingFilter: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.removeBadgesFromSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-trash',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }

                                openDialog({
                                    dialogName: 'Badges',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.removeBadgesFromAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-trash',
                            command: () => {
                                openDialog({
                                    dialogName: 'Badges',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.categories'),
                    icon: 'pi pi-sitemap',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addCategoriesToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-sitemap',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Categories'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.addCategoriesToAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-sitemap',
                            command: () => {
                                openDialog({
                                    dialogName: 'Categories',
                                    usingFilter: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.removeCategoriesFromSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-trash',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Categories',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t(
                                'labels.removeCategoriesFromAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-trash',
                            command: () => {
                                openDialog({
                                    dialogName: 'Categories',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.discounts'),
                    icon: 'pi pi-euro',
                    disabled: isShopware5(
                        resolvedPlatform.value?.threeLetterId
                    ),
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addDiscountsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-sitemap',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }
                                openDialog({
                                    dialogName: 'Discounts'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.addDiscountsToAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-sitemap',
                            command: () => {
                                openDialog({
                                    dialogName: 'Discounts',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.weclappDocuments'),
                    icon: 'pi pi-file-pdf',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addWeclappDocumentsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }

                                openDialog({dialogName: 'WeclappDocuments'});
                            }
                        },
                        {
                            label: i18n.global.t(
                                'labels.addWeclappDocumentsToAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            command: () => {
                                openDialog({
                                    dialogName: 'WeclappDocuments',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.shopwareDocuments'),
                    icon: 'pi pi-file-pdf',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addShopwareDocumentsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                if (
                                    !mergedSelection.value ||
                                    mergedSelection.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProduct'
                                        )
                                    );
                                    return;
                                }
                                if (
                                    selectedProductsInShopware.value.length < 1
                                ) {
                                    toast.error(
                                        i18n.global.t(
                                            'messages.pleaseSelectAtLeastOneProductThatExistsInShopware'
                                        )
                                    );
                                    return;
                                }

                                openDialog({dialogName: 'ShopwareDocuments'});
                            }
                        },
                        {
                            label: i18n.global.t(
                                'labels.addShopwareDocumentsToAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-file-pdf',
                            command: () => {
                                openDialog({
                                    dialogName: 'ShopwareDocuments',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.promotions'),
                    icon: 'pi pi-percentage',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.editPromotionsOnSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-pencil',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({dialogName: 'Promotions'});
                            }
                        },

                        {
                            label: i18n.global.t('labels.editPromotionsOnAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-pencil',
                            command: () => {
                                openDialog({
                                    dialogName: 'Promotions',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.productHome.metaTagsSEO'),
                    icon: 'pi pi-tags',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.addMetatagsToSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-tag',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.addMetatagsToAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-tag',
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags',
                                    usingFilter: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.removeMetatagsFromSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-trash',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t(
                                'labels.removeMetatagsFromAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-trash',
                            command: () => {
                                openDialog({
                                    dialogName: 'Metatags',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },

                {
                    label: i18n.global.t('labels.compliance'),
                    icon: 'pi pi-check-square',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.editComplianceOnSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-pencil',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({dialogName: 'Compliance'});
                            }
                        },

                        {
                            label: i18n.global.t('labels.editComplianceOnAll', {
                                number: totalRecords.value
                            }),
                            icon: 'pi pi-pencil',
                            command: () => {
                                openDialog({
                                    dialogName: 'Compliance',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.tags'),
                    icon: 'pi pi-tags',
                    disabled: !tagsPermissionAvailable.value,
                    items: [
                        {
                            label: i18n.global.t('labels.addTagsToSelected', {
                                number: mergedSelection.value?.length || ''
                            }),
                            class: 'font-normal',
                            icon: 'pi pi-tag',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags'
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.addTagsToAll', {
                                number: totalRecords.value
                            }),
                            class: 'font-normal',
                            icon: 'pi pi-tag',
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags',
                                    usingFilter: true
                                });
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.removeTagsFromSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            class: 'font-normal',
                            icon: 'pi pi-trash',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags',
                                    isRemove: true
                                });
                            }
                        },
                        {
                            label: i18n.global.t('labels.removeTagsFromAll', {
                                number: totalRecords.value
                            }),
                            class: 'font-normal',
                            icon: 'pi pi-trash',
                            command: () => {
                                openDialog({
                                    dialogName: 'Tags',
                                    isRemove: true,
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                },
                {
                    label: i18n.global.t('labels.customAttributes'),
                    icon: 'pi pi-book',
                    items: [
                        {
                            label: i18n.global.t(
                                'labels.editCustomFieldsOnSelected',
                                {
                                    number: mergedSelection.value?.length || ''
                                }
                            ),
                            icon: 'pi pi-pencil',
                            disabled: mergedSelection.value.length < 1,
                            command: () => {
                                openDialog({dialogName: 'CustomFields'});
                            }
                        },

                        {
                            label: i18n.global.t(
                                'labels.editCustomFieldsOnAll',
                                {
                                    number: totalRecords.value
                                }
                            ),
                            icon: 'pi pi-pencil',
                            command: () => {
                                openDialog({
                                    dialogName: 'CustomFields',
                                    usingFilter: true
                                });
                            }
                        }
                    ]
                }
            ];
        });

        const closeAddUsingSelectedMainProducts = (
            forceResfresh: boolean = false
        ) => {
            closeDialog();
            if (forceResfresh) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const onCloseBulkBadgesCategoriesDialog = (
            forceResfresh: boolean = false
        ) => {
            closeDialog();
            if (forceResfresh) {
                selectedProducts.value = [];
                mergedSelection.value = [];
                loadLazyData();
            }
        };

        const editPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('products-edit') !== -1;
        });

        const tagsPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return user?.permissions?.indexOf('tags-edit') !== -1;
        });

        const showCommentsColumn = computed(() => {
            return (
                products.value &&
                products.value.some(
                    (item: any) => item.comments && item.comments.length > 0
                )
            );
        });

        const selectedProductsInShopware = computed(() => {
            return mergedSelection.value
                ? mergedSelection.value.filter((item: any) => item.shopwareData)
                : [];
        });

        const viewAccessoriesPermissionAvailable = computed(() => {
            const user = store.getters['auth/user'];
            return (
                user?.permissions?.indexOf('product-accessories-view') !== -1
            );
        });

        const showChangeLogIcon = computed(() => {
            return (
                changelog.value.length > 0 &&
                products.value &&
                changelog.value.some((item) =>
                    products.value.some(
                        (data: any) => item.entityId === data.articleNumber
                    )
                )
            );
        });

        const expandRow = (data: any) => {
            if (
                expandedRows.value?.length > 0 &&
                expandedRows.value.find((item: any) => {
                    return item.id === data.id;
                }) !== null
            ) {
                expandedRows.value = [];
            } else {
                expandedRows.value = products.value.filter(
                    (p: any) => p.id === data.id
                );
            }
        };

        const stockInSalesOrdersCalculation = (data: any) => {
            let stock = 0;
            if (!data.salesOrders || data.salesOrders.length < 1) {
                return stock;
            }

            data.salesOrders.forEach((salesOrder: any) => {
                if (
                    !salesOrder.orderItems ||
                    salesOrder.orderItems.length < 1
                ) {
                    return true;
                }

                salesOrder.orderItems.forEach((orderItem: any) => {
                    if (orderItem.articleNumber !== data.articleNumber) {
                        return true;
                    }

                    stock += orderItem.quantity
                        ? parseInt(orderItem.quantity)
                        : 0;
                    stock -= orderItem.shippedQuantity
                        ? parseInt(orderItem.shippedQuantity)
                        : 0;
                });
            });

            return stock;
        };

        const stockInInternalShipmentsCalculation = (data: any) => {
            if (!data.incomingGoods || data.incomingGoods.length < 1) {
                return 0;
            }

            let stock = 0;

            data.incomingGoods.forEach((incomingGood: any) => {
                if (
                    !incomingGood.incomingGoodsItems ||
                    incomingGood.incomingGoodsItems.length < 1
                ) {
                    return true;
                }

                incomingGood.incomingGoodsItems.forEach(
                    (incomingGoodsItem: any) => {
                        if (
                            incomingGoodsItem.articleNumber !==
                            data.articleNumber
                        ) {
                            return true;
                        }

                        stock += incomingGoodsItem.quantity
                            ? parseInt(incomingGoodsItem.quantity)
                            : 0;
                    }
                );
            });

            return stock;
        };

        const columns = [
            {
                field: 'articleNumber',
                header: i18n.global.t('labels.articleNumber'),
                optionDisabled: true
            },
            {
                field: 'name',
                header: i18n.global.t('labels.name'),
                optionDisabled: true
            },
            {
                field: 'description',
                header: i18n.global.t('labels.productDescription.webshopText')
            },
            {
                field: 'productCategory',
                header: i18n.global.t('labels.articleCategory')
            },
            {
                field: 'productBadges',
                header: i18n.global.t('labels.badges')
            },
            {
                field: 'productDiscounts',
                header: i18n.global.t('labels.discounts') + ' (SW6)'
            },
            {
                field: 'articleCategoryName',
                header: i18n.global.t('labels.articleWeclappCategory')
            },
            {
                field: 'stock',
                header: i18n.global.t('labels.stock')
            },
            {
                field: 'stockInSalesOrders',
                header: i18n.global.t('labels.stockInSalesOrders')
            },
            {
                field: 'stockInInternalShipments',
                header: i18n.global.t('labels.stockInInternalShipments')
            },
            {
                field: 'accessories',
                header: i18n.global.t('labels.articleAccessories')
            },
            {
                field: 'ean',
                header: i18n.global.t('labels.ean')
            },
            {
                field: 'manufacturerPartNumber',
                header: i18n.global.t('labels.mpn')
            },
            {
                field: 'manufacturerName',
                header: i18n.global.t('labels.manufacturer')
            },
            {
                field: 'sellOut',
                header: i18n.global.t('labels.productHome.sellOut')
            },
            {
                field: 'metaTags',
                header: i18n.global.t('labels.productHome.metaTagsSEO')
            },
            {
                field: 'bulkGoods',
                header: i18n.global.t('labels.productHome.bulkGoods')
            },
            {
                field: 'blackWeekPromoFlag',
                header: i18n.global.t('labels.productPromotions.promoFlag')
            },

            {
                field: 'orderPositionCheckStock',
                header: i18n.global.t(
                    'labels.productHome.orderPositionCheckStock'
                )
            },
            {
                field: 'tempSelect',
                header: i18n.global.t('labels.productHome.tempSelect')
            },
            {
                field: 'activeInShop',
                header: i18n.global.t('labels.productHome.activeInShop')
            },
            {
                field: 'availableInShop',
                header: i18n.global.t('labels.productHome.availableInShop')
            },
            {
                field: 'priceOnRequest',
                header: i18n.global.t('labels.productHome.priceUponRequest')
            },
            {
                field: 'vkPrice',
                header: i18n.global.t('labels.vkPrice')
            },
            {
                field: 'ekPrice',
                header: i18n.global.t('labels.ekPrice')
            },
            {
                field: 'uvpPrice',
                header: 'UVP'
            },
            {
                field: 'shortDescription1',
                header: i18n.global.t(
                    'labels.productDescription.shortDescription'
                )
            },
            /*{
                field: 'teltecProductNumber',
                header: i18n.global.t('labels.teltecProductNumber')
            },
            {
                field: 'videodataProductNumber',
                header: i18n.global.t('labels.videodataProductNumber')
            },*/
            {
                field: 'createdDate',
                header: i18n.global.t('labels.createdAt')
            },
            {
                field: 'tags',
                header: i18n.global.t('labels.tags')
            }
        ].sort((a, b) => a.header.localeCompare(b.header));
        const visibleColumns = ref([
            'articleNumber',
            'name',
            'description',
            'productCategory',
            'accessories',
            'ean',
            'manufacturerName'
        ]);

        const selectedColumns = ref([
            'articleNumber',
            'name',
            'description',
            'productCategory',
            'accessories',
            'ean',
            'manufacturerName'
        ]);

        const onToggleColumnVisibility = (value: any) => {
            const cols = (value.value || []).concat(['articleNumber', 'name']);

            selectedColumns.value = cols.filter((c: string, index: number) => {
                return cols.indexOf(c) === index;
            });
        };

        const onConfirmColumnSelection = () => {
            visibleColumns.value = selectedColumns.value.filter(() => {
                return true;
            });

            columnSelectorComponent.value.hide();
            loadLazyData();
        };

        const onCancelColumnSelection = () => {
            selectedColumns.value = visibleColumns.value.filter(() => {
                return true;
            });

            columnSelectorComponent.value.hide();
        };

        const showCommentsDialog = (data: any) => {
            comments.value = Object.assign(data, {});
            openDialog({dialogName: 'Comments'});
        };

        const onAddNewProductClick = () => {
            router.push({
                name: 'ProductsAddWizard'
            });
        };

        const resolvedShopwareUrl = (
            url: string,
            shopwareData: {
                name: string | null;
                seoUrl: string | null;
            },
            threeLetterId: string
        ) => {
            return (
                url +
                (shopwareData.seoUrl !== null
                    ? shopwareData.seoUrl
                    : getShopwareUrl(shopwareData.name, threeLetterId) +
                      (threeLetterId === 'vct' ? '' : '.html'))
            );
        };

        const historyFilters = computed(() => {
            return JSON.stringify(lazyParams.value);
        });

        const onRowSelect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowSelectAll = (selection: {
            data: Array<any>;
            originalEvent: any;
        }) => {
            mergedSelection.value = Object.values(
                [...mergedSelection.value, ...selection.data].reduce(
                    (acc, obj) => ({...acc, [obj.id]: obj}),
                    {}
                )
            );
        };

        const onRowUnselect = (selection: {data: any; originalEvent: any}) => {
            mergedSelection.value = mergedSelection.value.filter(
                (item) => item.id !== selection.data.id
            );
        };

        const onRowUnselectAll = () => {
            mergedSelection.value = mergedSelection.value.filter((item) => {
                return !products.value.some((pr: any) => item.id === pr.id);
            });
        };

        const clearSelection = () => {
            selectedProducts.value = [];
            mergedSelection.value = [];
        };

        const bulkMenuToggle = (event: any) => {
            bulkMenu.value.toggle(event);
        };

        const layoutConfig = computed(() => {
            return store.getters['ui/layoutConfig'] as ILayoutConfig;
        });

        return {
            loading,
            loadLazyData,
            totalRecords,
            products,
            filters,
            selectedProducts,
            mergedSelection,
            selectedProductsInShopware,
            addBulkItems,
            expandedRows,
            dt,
            matchModesNumeric,
            matchModeWithEmptyFilter: [
                {label: 'Starts with', value: FilterMatchMode.STARTS_WITH},
                {label: 'Contains', value: FilterMatchMode.CONTAINS},
                {label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS},
                {
                    label: 'Ends with',
                    value: FilterMatchMode.ENDS_WITH
                },
                {label: 'Equals', value: FilterMatchMode.EQUALS},
                {
                    label: 'Not equals',
                    value: FilterMatchMode.NOT_EQUALS
                },
                {
                    label: 'Ends with',
                    value: FilterMatchMode.ENDS_WITH
                },
                {
                    label: 'Is Empty',
                    value: 'isEmpty'
                },
                {
                    label: 'Is Not empty',
                    value: 'isNotEmpty'
                }
            ],
            onFilter,
            onPage,
            onSort,
            onStateSave,
            onStateRestore,
            closeAddUsingSelectedMainProducts,
            expandRow,
            rowClass: (data: any) => {
                const colorVariant = layoutConfig.value?.darkTheme ? 800 : 200;
                return data?.shopwareData
                    ? data?.shopwareData?.active
                        ? ''
                        : data.customAttributes?.article_available_in_shop
                        ? 'bg-yellow-' + colorVariant
                        : 'bg-pink-' + colorVariant
                    : 'bg-red-' + colorVariant;
            },
            isEmptyKeydownCalldown: (model: any, callb: any) => {
                if (model.value.trim() === '=') {
                    model.matchMode = 'isEmpty';
                } else if (model.value.trim() === '*') {
                    model.matchMode = 'isNotEmpty';
                }
                callb();
            },
            editPermissionAvailable,
            viewAccessoriesPermissionAvailable,
            stripTagsAndTruncate,
            onCloseBulkBadgesCategoriesDialog,
            vkPriceCalculation,
            ekPriceCalculation,
            uvpPriceCalculation,
            stockInSalesOrdersCalculation,
            stockInInternalShipmentsCalculation,
            columns,
            selectedColumns,
            visibleColumns,
            onToggleColumnVisibility,
            onConfirmColumnSelection,
            onCancelColumnSelection,
            columnSelectorComponent,
            comments,
            showCommentsDialog,
            closeDialog,
            showCommentsColumn,
            resolvedShopwareUrl,
            getBadgeName,
            isValueEqualCaseInsensitive,
            changelog,
            showChangeLogIcon,
            onAddNewProductClick,
            historyFilters,
            applyFilters,
            clearFilters,
            onRowSelect,
            onRowUnselect,
            onRowSelectAll,
            onRowUnselectAll,
            clearSelection,
            dialogs,
            renderShopwareCategory,
            bulkMenu,
            bulkMenuToggle,
            availableProductTags,
            resolvedPlatform,
            secondPlatform
        };
    }
};
