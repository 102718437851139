import apiClient from '@/utils/axios';
import {clearEmptyFilters} from '@/utils/helpers';
import {AxiosResponse} from 'axios';

export const getAll = (input: any): Promise<AxiosResponse> => {
    const rows = input.rows || 10;

    const params = Object.assign(
        {allEnvs: input.allEnvs},
        {
            start: input.first || 0,
            end: (input.first || 0) + rows,
            sortField: input.sortField || '',
            sortOrder: (input.sortOrder || 1) === 1 ? 'asc' : 'desc',
            columns: JSON.stringify(input.columns || []),
            filters: JSON.stringify(clearEmptyFilters(input.filters || {})),
            filterConjunction: input.filterConjunction || 'and'
        },
        input.platform
            ? {
                  platform: input.platform
              }
            : {}
    );

    return apiClient.get('/api/v1/price-imports', {params});
};

export const addSupplierConfiguration = (
    supplierName: string,
    supplierNumber: string
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/price-supplier-configuration', {
        supplierName,
        supplierNumber
    });
};

export const addSupplierConfigurationDiscountGroup = (
    configurationId: string,
    groupName: string,
    discount: number,
    manufacturerId: string | null,
    manufacturerName: string | null
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/price-supplier-configuration/' +
            configurationId +
            '/discount-group',
        {
            action: 'add',
            groupName,
            discount,
            manufacturerId,
            manufacturerName
        }
    );
};

export const removeSupplierConfigurationDiscountGroup = (
    configurationId: string,
    id: number
): Promise<AxiosResponse> => {
    return apiClient.put(
        '/api/v1/price-supplier-configuration/' +
            configurationId +
            '/discount-group',
        {
            action: 'delete',
            id
        }
    );
};

export const getSupplierConfiguration = (
    input: any = null
): Promise<AxiosResponse> => {
    //{"supplierName":{"value":"riwi","matchMode":"contains"}}

    if (!input) {
        return apiClient.get('/api/v1/price-supplier-configuration');
    }

    const rows = input.rows || 10;

    const params = Object.assign({
        start: input.first || 0,
        end: (input.first || 0) + rows,
        filters: JSON.stringify(clearEmptyFilters(input.filters || {}))
    });

    return apiClient.get('/api/v1/price-supplier-configuration', {params});
};

export const updateSupplierConfiguration = (
    id: any,
    payload: any
): Promise<AxiosResponse> => {
    return apiClient.put('/api/v1/price-supplier-configuration/' + id, payload);
};

export const getSingle = (
    id: any,
    includeRegular: boolean = true,
    includeIrregular: boolean = true
): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/price-imports/' + id, {
        params: {
            includeRegular,
            includeIrregular
        }
    });
};

export const downloadFile = (
    importId: string,
    fileId: string,
    transformed: boolean = false
): Promise<AxiosResponse> => {
    return apiClient.get(
        '/api/v1/price-imports/' +
            importId +
            '/download-source-file/' +
            fileId +
            (transformed ? '?transformed' : ''),
        {
            responseType: 'blob'
        }
    );
};

export const getAllTemplates = (params: any): Promise<AxiosResponse> => {
    return apiClient.get('/api/v1/price-import-templates', {params});
};

export const addTemplate = async (
    supplierNumber: string,
    sheetName: string | null,
    manufacturer: string | null,
    payload: any,
    definition: any
): Promise<AxiosResponse> => {
    return apiClient.post('/api/v1/price-import-templates', {
        ...payload,
        supplierNumber,
        sheetName,
        manufacturer,
        definition
    });
};

export const removeTemplate = async (
    itemId: string
): Promise<AxiosResponse> => {
    return apiClient.delete('/api/v1/price-import-templates/' + itemId);
};
