import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f3795d2"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "grid p-fluid"
}
const _hoisted_2 = { class: "col field" }
const _hoisted_3 = {
  key: 0,
  class: "ml-3"
}
const _hoisted_4 = { class: "ml-3" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "grid p-fluid" }
const _hoisted_7 = { class: "col field" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "grid p-fluid" }
const _hoisted_10 = { class: "col field" }
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = { class: "grid p-fluid" }
const _hoisted_13 = { class: "col field" }
const _hoisted_14 = {
  key: 0,
  class: "p-error"
}
const _hoisted_15 = { class: "col field" }
const _hoisted_16 = { class: "grid p-fluid" }
const _hoisted_17 = { class: "col field" }
const _hoisted_18 = { class: "col field" }
const _hoisted_19 = { class: "grid p-fluid" }
const _hoisted_20 = { class: "col field" }
const _hoisted_21 = { class: "grid p-fluid" }
const _hoisted_22 = { class: "field col" }
const _hoisted_23 = { class: "w-full flex justify-content-between mt-4" }

export function render(_ctx, _cache) {
  const _component_AutoComplete = _resolveComponent("AutoComplete")
  const _component_p_dropdown = _resolveComponent("p-dropdown")
  const _component_p_calendar = _resolveComponent("p-calendar")
  const _component_InputText = _resolveComponent("InputText")
  const _component_p_fieldset = _resolveComponent("p-fieldset")
  const _component_InputSwitch = _resolveComponent("InputSwitch")
  const _component_p_checkbox = _resolveComponent("p-checkbox")
  const _component_p_textarea = _resolveComponent("p-textarea")
  const _component_p_button = _resolveComponent("p-button")

  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[12] || (_cache[12] = _withModifiers($event => (_ctx.handleSubmit(!_ctx.v$.$invalid)), ["prevent"]))
  }, [
    (_ctx.isReturnTicket)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_AutoComplete, {
              modelValue: _ctx.v$.order.$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.v$.order.$model) = $event)),
              suggestions: _ctx.filteredOrders,
              placeholder: _ctx.$t('labels.ticket.retoure.orderOrInvoiceNumber'),
              onComplete: _cache[1] || (_cache[1] = $event => (_ctx.searchOrders($event))),
              dropdown: "",
              optionLabel: "label",
              forceSelection: "",
              class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.order.$invalid && _ctx.submitted}])
            }, {
              item: _withCtx((slotProps) => [
                _createElementVNode("b", null, _toDisplayString(slotProps.item.item.orderNumber), 1),
                (slotProps.item.invoice)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
                      _createTextVNode("[" + _toDisplayString(_ctx.$t('labels.ticket.retoure.invoiceNumber')) + ": ", 1),
                      _createElementVNode("b", null, _toDisplayString(slotProps.item.invoice.invoiceNumber), 1),
                      _createTextVNode("]")
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("span", _hoisted_4, "[" + _toDisplayString(slotProps.item.item.customer.customerNumber) + " " + _toDisplayString(slotProps.item.item.customer.company ||
                            slotProps.item.item.customer.firstName + ' ' +
                            slotProps.item.item.customer.lastName) + "]", 1)
              ]),
              _: 1
            }, 8, ["modelValue", "suggestions", "placeholder", "class"]),
            ((_ctx.v$.order.$invalid && _ctx.submitted) || _ctx.v$.order.$pending.$response)
              ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.v$.order.required.$message.replace('The value',
                    _ctx.$t('labels.ticket.retoure.orderOrInvoiceNumber'))), 1))
              : _createCommentVNode("", true)
          ])
        ]))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_AutoComplete, {
                modelValue: _ctx.v$.customer.$model,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ((_ctx.v$.customer.$model) = $event)),
                placeholder: _ctx.$t('labels.ticket.retoure.customer'),
                suggestions: _ctx.filteredCustomers,
                onComplete: _cache[3] || (_cache[3] = $event => (_ctx.searchCustomers($event))),
                dropdown: true,
                optionLabel: "label",
                optionValue: "value",
                forceSelection: "",
                class: _normalizeClass(["w-full", {'p-invalid':_ctx.v$.customer.$invalid && _ctx.submitted}])
              }, null, 8, ["modelValue", "placeholder", "suggestions", "class"]),
              ((_ctx.v$.customer.$invalid && _ctx.submitted) || _ctx.v$.customer.$pending.$response)
                ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.v$.customer.required.$message), 1))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_p_dropdown, {
                filter: true,
                placeholder: _ctx.$t('labels.ticket.retoure.warehouse'),
                modelValue: _ctx.v$.warehouseId.$model,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.v$.warehouseId.$model) = $event)),
                options: _ctx.warehouseOptions,
                optionLabel: "label",
                optionValue: "value",
                onChange: _cache[5] || (_cache[5] = $event => (_ctx.setDropdownValue('warehouseId', $event))),
                class: _normalizeClass([{'p-invalid':_ctx.v$.warehouseId.$invalid && _ctx.submitted}, "w-full"]),
                showClear: true
              }, null, 8, ["placeholder", "modelValue", "options", "class"]),
              ((_ctx.v$.warehouseId.$invalid && _ctx.submitted) || _ctx.v$.warehouseId.$pending.$response)
                ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.v$.warehouseId.required.$message), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ], 64)),
    _createVNode(_component_p_fieldset, {
      class: "mb-3",
      legend: _ctx.$t('labels.ticket.referenceDetails')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.deliveryNoteDate')), 1),
            _createVNode(_component_p_calendar, {
              placeholder: _ctx.$t('labels.ticket.retoure.deliveryNoteDate'),
              class: _normalizeClass(["w-full", {'tt-form-input': true, 'p-invalid':_ctx.v$.deliveryNoteDate.$invalid && _ctx.submitted}]),
              autocomplete: "off",
              dateFormat: "dd.mm.yy",
              modelValue: _ctx.v$.deliveryNoteDate.$model,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.v$.deliveryNoteDate.$model) = $event))
            }, null, 8, ["placeholder", "modelValue", "class"]),
            ((_ctx.v$.deliveryNoteDate.$invalid && _ctx.submitted) || _ctx.v$.deliveryNoteDate.$pending.$response)
              ? (_openBlock(), _createElementBlock("small", _hoisted_14, _toDisplayString(_ctx.v$.deliveryNoteDate.required.$message.replace('Value',
                    _ctx.$t('labels.ticket.retoure.deliveryNoteDate'))), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.customerReference')), 1),
            _createVNode(_component_InputText, {
              autocomplete: "off",
              modelValue: _ctx.v$.customerReference.$model,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => ((_ctx.v$.customerReference.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.customerReference.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["legend"]),
    _createVNode(_component_p_fieldset, {
      legend: _ctx.$t('labels.ticket.serviceDetails')
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("div", _hoisted_17, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.isChargeable')), 1),
            _createVNode(_component_InputSwitch, {
              modelValue: _ctx.v$.isChargeable.$model,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.v$.isChargeable.$model) = $event)),
              class: _normalizeClass({'p-invalid':_ctx.v$.isChargeable.$invalid && _ctx.submitted})
            }, null, 8, ["modelValue", "class"])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.serviceReceipt')), 1),
            _createVNode(_component_p_checkbox, {
              modelValue: _ctx.v$.serviceReceipt.$model,
              "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.v$.serviceReceipt.$model) = $event)),
              binary: true
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.internalErrorDescription')), 1),
            _createVNode(_component_p_textarea, {
              rows: "4",
              modelValue: _ctx.v$.internalErrorDescription.$model,
              "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.v$.internalErrorDescription.$model) = $event))
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("label", null, _toDisplayString(_ctx.$t('labels.ticket.retoure.customerErrorDescription')), 1),
            _createVNode(_component_p_textarea, {
              rows: "4",
              modelValue: _ctx.v$.customerErrorDescription.$model,
              "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => ((_ctx.v$.customerErrorDescription.$model) = $event))
            }, null, 8, ["modelValue"])
          ])
        ])
      ]),
      _: 1
    }, 8, ["legend"]),
    _createElementVNode("div", _hoisted_23, [
      _createVNode(_component_p_button, {
        severity: "danger",
        textContent: _toDisplayString(_ctx.$t('buttons.cancel')),
        onClick: _ctx.onBackButtonClicked
      }, null, 8, ["textContent", "onClick"]),
      _createVNode(_component_p_button, {
        severity: "success",
        textContent: _toDisplayString(_ctx.$t('buttons.next')),
        type: "submit"
      }, null, 8, ["textContent"])
    ])
  ], 32))
}